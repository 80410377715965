/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Component } from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Rotator from "../components/rotator"
import { Link } from "gatsby"
import { sizes } from "../gatsby-plugin-theme-ui"
import "../styles/global.css"

class Plugins extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Plugins</title>
          <meta
            name="description"
            content="The library for searching and exploring JimTheFish's array of features!"
          />
        </Helmet>
        <Layout
          // overrideCSS={{
          //   alignItems: `center`,
          //   display: `flex`,
          //   flexDirection: `column`,
          //   minHeight: `calc(100vh - (${sizes.headerHeight} + ${sizes.bannerHeight}))`,
          // }}
        >
          <div>&nbsp;<br /><br /><br /></div>
          <div
            css={{
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            <h1
              // sx={{
              //   fontSize: 6,
              //   fontWeight: `heading`,
              //   mx: 5,
              //   mb: 0,
              //   textAlign: `center`,
              // }}
              className="mx-5 mb-0"
              style={{fontSize: `2rem`, fontWeight: `600`, textAlign: `center`}}
            >
              Looking for something in particular?
            </h1>
            <div className="feature-slider">
            <Rotator
              items={[
                {
                  text: `SEO?`,
                  pluginName: `/page-2`,
                },
                {
                  text: `responsive images?`,
                  pluginName: `/page-css`,
                },
                {
                  text: `offline support?`,
                  pluginName: `/page-test`,
                },
                {
                  text: `a sitemap?`,
                  pluginName: `/sitemap`,
                },
                {
                  text: `a great blog?`,
                  pluginName: `/blog`,
                },
                {
                  text: `blog post 1?`,
                  pluginName: `/blog/2019-01-01-post1`,
                },
                {
                  text: `blog post 2?`,
                  pluginName: `/blog/2019-02-02-post2`,
                },
                {
                  text: `anything?`,
                },
              ]}
              color="RoyalBlue"
             />
            </div>
            <p
              sx={{
                color: `textMuted`,
                fontSize: 2,
                textAlign: `center`,
              }}
            >
              Please browse the site for more information and 
              contact me if you have any questions.  The site should be 
              blazing fast - please let me know if that's not the case!
              Navigate back to the <Link to="/">home page</Link>  or visit 
              the <Link to="/blog">blog page</Link> for more cool content!
            </p>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default Plugins
